<template>
  <div class="promocode-container">
    <connect-modal :show="deletePromocode !== null" :width="'450px'" bkcolor="#F7F7F7">
      <div class="ui-cancelled-sub-mollie-close-button" style="margin-left: auto" @click="deletePromocode = null">
        <close-circle-icon class="ui-cancelled-sub-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <div class="promo-code__modal">
        <div class="promo-code__modal--title">
          {{ `${$t('vue.delete')} ${deletePromocodeName} ?` }}
        </div>
        <div class="promo-code__modal--action mt-5">
          <vs-button icon-pack="feather" color="#e8546f" type="filled" @click="onConfirmDelete" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
          <vs-button icon-pack="feather" color="#e8546f" type="border" @click="deletePromocode = null" class="ml-2">{{ $t('vue.cancel') }}</vs-button>
        </div>
      </div>
    </connect-modal>

    <div class="promocode-container--btn">
      <vs-button v-if="!mode" icon-pack="feather" icon="icon-plus" type="filled" @click="onAddPromocode">{{ $t('promocode.addPromocode') }}</vs-button>
    </div>

    <div v-if="showProgress" class="promocode-container--btn">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="promo-codes" :class="{ 'promo-codes--center': (promoCode && promoCode.id) || mode !== null }">
      <!-- <transition name="fade-bottom-2x"> -->
      <div v-show="showList" class="promo-codes">
        <div class="promo-codes__box" v-for="(promocode, index) in promocodes" :key="index">
          <div class="promo-codes__box--switch" @click="togglePromocode(promocode)">
            <toggle-switch-icon :width="32" :height="32" :enabled="promocode.enabled" />
          </div>
          <div class="promo-codes__box--name" @click="togglePromocode(promocode)">
            {{ promocode.name }}
          </div>

          <div class="promo-codes__box__actions">
            <div class="promo-codes__box__actions--edit">
              <feather-icon icon="EditIcon" :style="{ color: '#275D73' }" @click="onEditPromocode(promocode)" />
            </div>
            <div class="promo-codes__box__actions--delete">
              <feather-icon icon="TrashIcon" :style="{ color: '#f05541' }" @click="onDeletePromocode(promocode)" />
            </div>
          </div>
        </div>
      </div>
      <!-- </transition>
      <transition name="fade-bottom-2x"> -->
      <div v-show="showForm" class="promo-codes__edit" :key="formKey">
        <div class="promo-codes__edit--close" @click="onCloseEdit">
          <close-icon :width="20" :height="20" color="#575757" />
        </div>
        <div class="promo-codes__edit__form">
          <form>
            <div class="promo-codes__edit__form__fields">
              <div class="promo-codes__edit__form__fields__field">
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    ref="name"
                    type="text"
                    class="promo-codes__edit__form__fields__field__input"
                    v-model="name"
                    v-validate="'required|validateStringForSpecialCharacters'"
                    data-vv-validate-on="blur"
                    name="name"
                    :label-placeholder="$t('promocode.name')"
                  />

                  <div
                    class="promo-codes__edit__form__fields__field__label"
                    :class="{ 'promo-codes__edit__form__fields__field__label--moved': name && name.length > 0 }"
                    @click="$refs.name.focus()"
                  >
                    {{ $t('promocode.name') }}
                  </div>
                </div>

                <span v-if="errors.first('name')" class="promo-codes__edit__form__fields__field--error flex items-center" style="margin-top: 5px">
                  {{ errors.first('name') }}
                </span>
              </div>

              <div class="promo-codes__edit__form__fields__field">
                <div>{{ $t('promocode.chooseType') }}</div>
                <div class="promo-codes__edit__form__radio">
                  <div
                    class="promo-codes__edit__form__radio"
                    v-for="type in [
                      { text: 'Percent', value: 'percent' },
                      { text: 'Amount', value: 'amount' }
                    ]"
                    :key="type.value"
                  >
                    <div class="promo-codes__edit__form__radio--input">
                      <input
                        type="radio"
                        :name="$t('promocode.input.typeOfDiscount')"
                        v-validate="'required'"
                        v-model="discountType"
                        :value="type.value"
                        :key="formKey"
                      />
                    </div>
                    <div class="promo-codes__edit__form__radio--label">
                      <label>{{ type.text }}</label>
                    </div>
                  </div>
                </div>

                <span
                  v-if="errors.first($t('promocode.input.typeOfDiscount'))"
                  class="promo-codes__edit__form__fields__field--error flex items-center"
                  style="margin-top: 5px"
                >
                  {{ errors.first($t('promocode.input.typeOfDiscount')) }}
                </span>
              </div>
            </div>

            <div class="promo-codes__edit__form__fields">
              <div v-if="discountType === 'percent'" class="promo-codes__edit__form__fields__field">
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    type="number"
                    class="promo-codes__edit__form__fields__field__input promo-codes__edit__form__fields__field__input--small"
                    step="0.01"
                    placeholder="Discount"
                    v-model="discountPercent"
                    v-validate="'required'"
                    name="Discount Percent"
                    data-vv-as="Discount Percent"
                    validate-on="blur"
                  />
                  %
                </div>
                <span v-if="errors.first('Discount Percent')" class="promo-codes__edit__form__fields__field--error flex items-center" style="margin-top: 5px">
                  {{ errors.first('Discount Percent') }}
                </span>
              </div>

              <div v-if="discountType === 'amount'" class="promo-codes__edit__form__fields__field">
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    type="number"
                    class="promo-codes__edit__form__fields__field__input promo-codes__edit__form__fields__field__input--small"
                    step="0.01"
                    placeholder="Discount"
                    v-model="discountEUR"
                    v-validate="'required'"
                    name="Discount Amount (EUR)"
                    data-vv-as="Discount Amount (EUR)"
                    validate-on="blur"
                  />
                  EUR €
                </div>
                <span
                  v-if="errors.first('Discount Amount (EUR)')"
                  class="promo-codes__edit__form__fields__field--error flex items-center"
                  style="margin-top: 5px"
                >
                  {{ errors.first('Discount Amount (EUR)') }}
                </span>
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    type="number"
                    class="promo-codes__edit__form__fields__field__input promo-codes__edit__form__fields__field__input--small"
                    step="0.01"
                    placeholder="Discount"
                    v-model="discountUSD"
                    v-validate="'required'"
                    name="Discount Amount (USD)"
                    data-vv-as="Discount Amount (USD)"
                    validate-on="blur"
                  />
                  USD $
                </div>
                <span
                  v-if="errors.first('Discount Amount (USD)')"
                  class="promo-codes__edit__form__fields__field--error flex items-center"
                  style="margin-top: 5px"
                >
                  {{ errors.first('Discount Amount (USD)') }}
                </span>
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    type="number"
                    class="promo-codes__edit__form__fields__field__input promo-codes__edit__form__fields__field__input--small"
                    step="0.01"
                    placeholder="Discount"
                    v-model="discountAUD"
                    v-validate="'required'"
                    name="Discount Amount (AUD)"
                    data-vv-as="Discount Amount (AUD)"
                    validate-on="blur"
                  />
                  AUD $
                </div>
                <span
                  v-if="errors.first('Discount Amount (AUD)')"
                  class="promo-codes__edit__form__fields__field--error flex items-center"
                  style="margin-top: 5px"
                >
                  {{ errors.first('Discount Amount (AUD)') }}
                </span>
                <div class="promo-codes__edit__form__fields__field__inner">
                  <input
                    type="number"
                    class="promo-codes__edit__form__fields__field__input promo-codes__edit__form__fields__field__input--small"
                    step="0.01"
                    placeholder="Discount"
                    v-model="discountGBP"
                    v-validate="'required'"
                    name="Discount Amount (GBP)"
                    data-vv-as="Discount Amount (GBP)"
                    validate-on="blur"
                  />
                  GBP £
                </div>
                <span
                  v-if="errors.first('Discount Amount (GBP)')"
                  class="promo-codes__edit__form__fields__field--error flex items-center"
                  style="margin-top: 5px"
                >
                  {{ errors.first('Discount Amount (GBP)') }}
                </span>
              </div>
            </div>

            <div class="promo-codes__edit__form__fields__field">
              <div class="promo-codes__edit__form__fields__field__inner">
                <multiselect
                  ref="plans"
                  v-model="plans"
                  :options="plansList"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :placeholder="''"
                  :selectLabel="''"
                  :selectedLabel="''"
                  :deselectLabel="''"
                  label="text"
                  track-by="value"
                  name="Plans"
                  class="promo-codes__edit__form__fields__field__select promo-codes__edit__form__fields__field__select-checkbox"
                  open-direction="bottom"
                  :preserve-search="true"
                  :preselect-first="false"
                  @close="isOpenedPlansSelect = false"
                  @open="isOpenedPlansSelect = true"
                  v-validate="'required'"
                >
                  <template slot="option" slot-scope="{ option }">
                    <div class="promo-codes__edit__form__fields__field__select__checkbox" />

                    {{ option.text }}
                  </template>

                  <template slot="noResult">No results found</template>
                </multiselect>

                <div
                  class="promo-codes__edit__form__fields__field__label"
                  :class="{
                    'promo-codes__edit__form__fields__field__label--moved': (!!plans && plans.length) || isOpenedPlansSelect,
                    'promo-codes__edit__form__fields__field__label--over': isOpenedPlansSelect
                  }"
                  @click="$refs.plans.$el.focus()"
                >
                  {{ $t('promocode.choosePlans') }}
                </div>
              </div>

              <span v-if="errors.first('Plans')" class="promo-codes__edit__form__fields__field--error flex items-center" style="margin-top: 5px">
                {{ errors.first('Plans') }}
              </span>
            </div>

            <div class="promo-codes__edit__form__fields__field">
              <div class="promo-codes__edit__form__fields__field__inner">
                <label>Valid Until</label>
                <datepicker
                  :key="formKey"
                  v-model="expiryDate"
                  format="MM/dd/yyyy"
                  :inline="false"
                  :disabledDates="{ to: new Date(Date.now() - 8640000) }"
                ></datepicker>
              </div>
            </div>
          </form>
        </div>
        <div class="promo-codes-action mt60" @click="onSavePromoCode">
          {{ $t('vue.save') }}
        </div>
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'
import dayjs from 'dayjs'

export default {
  name: 'PromoCodeList',
  components: {
    ToggleSwitchIcon,
    CloseIcon,
    Multiselect,
    Datepicker,
    ConnectModal,
    CloseCircleIcon
  },
  data() {
    return {
      deletePromocode: null,
      format: 'MM/dd/yyyy',
      showProgress: true,

      expiryDate: null,
      name: '',
      discountType: null,
      discountPercent: null,
      discountAUD: null,
      discountEUR: null,
      discountGBP: null,
      discountUSD: null,
      promoCode: null,
      plans: [],

      isOpenedPlansSelect: false,

      mode: null,

      formKey: Math.random().toString(36).substring(2, 15),
      promocodes: [],

      plansList: [
        { text: "Let's Connect Basic Monthly", value: 'M_BASIC_MONTHLY' },
        { text: "Let's Connect Basic Yearly", value: 'M_BASIC_ANNUAL' },
        { text: "Let's Connect Pro Monthly", value: 'M_PROFESSIONAL_MONTHLY' },
        { text: "Let's Connect Pro Yearly", value: 'M_PROFESSIONAL_ANNUAL' },
        { text: "Let's Connect Enterprise Monthly", value: 'M_ENTERPRISE_MONTHLY' },
        { text: "Let's Connect Enterprise Yearly", value: 'M_ENTERPRISE_ANNUAL' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    deletePromocodeName() {
      return this.deletePromocode && this.deletePromocode.name ? this.deletePromocode.name : ''
    },
    showList() {
      if ((!this.promoCode || !this.promoCode) && this.mode === null) return true
      return false
    },
    showForm() {
      if ((this.promoCode && this.promoCode.id) || this.mode !== null) return true
      return false
    }
  },

  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },

  async mounted() {
    const promoCodesRef = await this.$db.collection('promo-codes').orderBy('created', 'desc').get()
    promoCodesRef.docs.forEach(async (doc) => {
      const promocode = doc.data()
      promocode.id = doc.id
      this.promocodes.push(promocode)
    })
    this.showProgress = false
  },

  methods: {
    setDefaultValues() {
      this.$validator.pause()
      this.expiryDate = null
      this.name = ''
      this.discountType = 'percent'
      this.discountPercent = 0.0
      this.discountAUD = null
      this.discountEUR = null
      this.discountGBP = null
      this.discountUSD = null
      this.promoCode = null
      this.plans = []
      this.deletePromocode = null
      this.$validator.reset()
    },
    async onConfirmDelete() {
      await this.$vs.loading()
      await this.$db.collection('promo-codes').doc(this.deletePromocode.id).delete()
      this.promocodes = this.promocodes.filter((x) => x.id !== this.deletePromocode.id)
      this.deletePromocode = null
      this.promoCode = null
      await this.$vs.loading.close()
    },
    onDeletePromocode(promocode) {
      this.deletePromocode = promocode
    },
    async onSavePromoCode() {
      const result = await this.$validator.validateAll()
      if (!result || !['edit', 'add'].includes(this.mode)) return

      const promocode = {
        name: this.name,
        type: this.discountType
      }
      if (this.discountType === 'percent') {
        promocode.discount = Number(this.discountPercent)
      }
      if (this.discountType === 'amount') {
        promocode.discount = {
          AUD: Number(this.discountAUD),
          EUR: Number(this.discountEUR),
          GBP: Number(this.discountGBP),
          USD: Number(this.discountUSD)
        }
      }
      promocode.plans = this.plans.map((x) => x.value)

      if (this.expiryDate) {
        promocode.expiryDate = this.expiryDate
      }

      if (this.mode === 'edit') {
        await this.$vs.loading()

        promocode.updated = dayjs().utc().toDate()
        promocode.updatedBy = this.activeUserInfo.uid
        promocode.updatedByName = this.activeUserInfo.displayName

        await this.$db.collection('promo-codes').doc(this.promoCode.id).set(promocode, { merge: true })

        const _promocode = {
          id: this.promoCode.id,
          name: promocode.name,
          type: promocode.type,
          discount: promocode.discount,
          plans: promocode.plans,
          enabled: this.promoCode.enabled
        }
        if (this.expiryDate) {
          _promocode.expiryDate = this.expiryDate
        }

        this.promocodes = this.promocodes.filter((x) => x.id !== this.promoCode.id)
        this.promocodes.push(_promocode)

        await this.$vs.loading.close()
      }

      if (this.mode === 'add') {
        await this.$vs.loading()

        promocode.created = dayjs().utc().toDate()
        promocode.createdBy = this.activeUserInfo.uid
        promocode.createdByName = this.activeUserInfo.displayName

        const promoCodeRef = await this.$db.collection('promo-codes').add(promocode)

        const _promocode = {
          id: promoCodeRef.id,
          name: promocode.name,
          type: promocode.type,
          discount: promocode.discount,
          plans: promocode.plans,
          enabled: false
        }
        if (this.expiryDate) {
          _promocode.expiryDate = this.expiryDate
        }

        this.promocodes.push(_promocode)

        await this.$vs.loading.close()
      }
      this.mode = null
      this.setDefaultValues()
      this.$validator.reset()
    },
    togglePromocode(promocode) {
      const enabled = promocode.enabled
      try {
        this.$db.collection('promo-codes').doc(promocode.id).set({ enabled: !promocode.enabled }, { merge: true })
        promocode.enabled = !promocode.enabled
      } catch (error) {
        promocode.enabled = enabled
        /* eslint-disable-next-line */
        console.log(error.message)
      }
    },
    onAddPromocode() {
      this.mode = 'add'
      this.setDefaultValues()
      this.$validator.reset()
    },
    onEditPromocode(promocode) {
      this.mode = 'edit'
      this.plans = this.plansList.filter((x) => promocode.plans.includes(x.value))
      this.promoCode = promocode
      this.name = promocode.name
      this.discountType = promocode.type

      if (promocode.expiryDate && promocode.expiryDate.seconds) {
        this.expiryDate = dayjs.unix(promocode.expiryDate.seconds).utc().toDate()
      }

      if (promocode.expiryDate && !promocode.expiryDate.seconds) {
        this.expiryDate = dayjs(promocode.expiryDate).utc().toDate()
      }

      if (promocode.type === 'percent') {
        this.discountPercent = promocode.discount
        this.discountAUD = null
        this.discountEUR = null
        this.discountUSD = null
        this.discountGBP = null
      }

      if (promocode.type === 'amount') {
        this.discountPercent = null
        this.discountAUD = promocode.discount.AUD
        this.discountEUR = promocode.discount.EUR
        this.discountUSD = promocode.discount.USD
        this.discountGBP = promocode.discount.GBP
      }
      this.$validator.reset()
    },
    onCloseEdit() {
      this.mode = null
      this.setDefaultValues()
      this.$validator.reset()
    }
  },
  watch: {
    async discountType() {
      await this.$validator.reset()
      this.formKey = Math.random().toString(36).substring(2, 15)
    }
  }
}
</script>

<style lang="scss">
.promo-codes__edit__form__fields__field__select {
  min-height: 60px;
  .multiselect__tag {
    color: #262629;
    background: #def1fe;
    &-icon {
      &:hover {
        background: #82ceff;
      }
    }
  }
  .multiselect__option {
    text-transform: capitalize;

    &.multiselect__option--highlight {
      color: #262629;
      background: #f1f9ff;
    }
  }

  .multiselect {
    &__tags {
      padding-top: 9px;
      min-height: 60px;
    }

    &__select {
      height: 55px;
    }

    &__single,
    &__input {
      padding-top: 10px;
    }

    &__content-wrapper {
      min-height: 200px !important;
    }
  }

  &-checkbox {
    .multiselect__option {
      display: flex;
      padding-left: 12px;

      &--selected {
        .promo-codes__edit__form__fields__field__select__checkbox {
          background-image: url('../../../../assets/images/pages/login/Vector-7.svg');
          background-position: 50% 50%;
          background-size: auto;
          background-repeat: no-repeat;
          background-color: rgb(130, 206, 255);
          border-color: rgb(130, 206, 255);
        }
      }
    }
  }

  &__checkbox {
    margin-right: 12px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border: 1px solid rgb(217, 219, 221);
    border-radius: 4px;
  }
}
</style>

<style lang="scss" scoped>
.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}

.promocode-container {
  display: flex;
  flex-direction: column;

  &--btn {
    padding: 25px;
  }
}

.mt60 {
  margin-top: 60px;
}

.promo-codes {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--center {
    justify-content: center;
  }

  &--full {
    width: 100%;
  }

  &__box {
    position: relative;
    width: 250px;
    height: 180px;
    background: #fff;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);
    }

    &--switch {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
    }
    &--name {
      position: absolute;
      top: 15px;
      left: 15px;
      font-weight: 700;
      font-size: 15px;
    }

    &__actions {
      display: flex;
      flex-direction: row;

      &--edit {
        width: 50px;
        height: 50px;
        background: rgba(var(--vs-primary), 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        margin: 0px 5px 0px 5px;
        cursor: pointer;
      }

      &--delete {
        width: 50px;
        height: 50px;
        background: rgba(var(--vs-danger), 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        margin: 0px 5px 0px 5px;
        cursor: pointer;
      }
    }
  }

  &-action {
    background: #3034f7;
    border-radius: 8px;
    width: 193px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    &--disabled {
      background: #d3d3d3;
    }

    cursor: pointer;
  }

  &__edit {
    width: calc(60vw);
    max-width: 720px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    position: relative;
    padding: 25px;
    flex-direction: column;

    &--close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &__form {
      width: 100%;
      margin-top: 10px;

      &__radio {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2px 10px 0px 0px;

        &--input {
          margin-right: 5px;
        }
        &--label {
          margin-right: 10px;
        }
      }

      &__fields {
        &__field {
          margin-top: 28px;

          @media (max-width: 991px) {
            margin-top: 15px;
          }

          &:first-of-type {
            margin-top: 0;
          }

          &__inner {
            position: relative;
          }

          &__label {
            position: absolute;
            top: 50%;
            left: 20px;
            padding: 0 2px;
            touch-action: none;
            color: #878894;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.111111px;
            background: #ffffff;
            transform: translateY(-50%);
            max-width: calc(100% - 50px);

            &--moved {
              top: 0;
              left: 15px;
              font-family: 'Larsseit-Bold';
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.0833333px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              transition: all 0.2s linear;
            }

            &--over {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              z-index: 98;
            }
          }

          &__input {
            padding: 5px 20px;
            width: 100%;
            height: 50px;
            color: #35495e;
            font-family: 'Larsseit-Regular';
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.111111px;
            background: rgb(255, 255, 255);
            border: 1px solid rgb(217, 219, 221);
            border-radius: 8px;
            /* transition is using for preventing the autofill styles of the input */
            transition: background-color 99999999ms;

            &:focus + .promo-codes__edit__form__fields__field__label,
            &:-webkit-autofill + .promo-codes__edit__form__fields__field__label {
              top: 0;
              left: 15px;
              font-family: 'Larsseit-Bold';
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.0833333px;
              transition: all 0.2s linear;
            }

            &--small {
              width: 130px;
              margin-top: 5px;
            }
          }

          &__rules-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;

            @media (max-width: 991px) {
              flex-direction: column;
              align-items: flex-start;
            }
          }

          &--error {
            color: rgb(181, 101, 102);
            font-size: 12px;
          }

          &__rule {
            display: inline-flex;
            align-items: center;
            margin-top: 5px;
            margin-right: 4px;
            padding: 4px 14px;
            color: #538fb5;
            font-family: 'Larsseit-Medium';
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.04375px;
            background: #e8f6ff;
            border-radius: 12px;

            &__icon {
              margin-right: 5px;
            }

            &--valid {
              color: rgb(65, 184, 131);
              background: rgba(65, 184, 131, 0.3);
            }

            &--invalid {
              color: rgb(181, 101, 102);
              background: rgb(255, 207, 207);
            }
          }
        }
      }
    }
  }
}
</style>
